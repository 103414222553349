import React from 'react'

import Layout from '../components/layout'
import PrivacyPolicy from '../components/privacyPolicy'

import HeroImage from '../images/scroodles-hero-image.jpg'

const IndexPage = () => (
  <Layout>
    <section className="section">
      <div className="container" style={{ textAlign: 'center' }}>
        <img
          src={HeroImage}
          style={{ width: '100%', marginBottom: '2rem' }}
          alt={'Scroodles'}
        />

        <p>
          Battle the mischievous 'Scroodles' in this fresh, fast, and fun word
          game! Its part-Scrabble, part-adventure, and ALL AWESOME. Try it for
          free! Can you spell A-D-D-I-C-T-I-N-G?
        </p>
        <div
          className="buttons"
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <a
            className="button is-black"
            href="https://itunes.apple.com/us/app/scroodles/id912236766?mt=8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download from iTunes Store
          </a>

          <a
            className="button is-black"
            href="https://play.google.com/store/apps/details?id=com.MadSallyGames.Scroodles"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download from Google Play Store
          </a>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%' /* 16:9 */,
            paddingTop: 25,
            height: 0
          }}
        >
          <iframe
            title="Scroodles Intro"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
            src="//youtube.com/embed/_M0CBxa0Tjc?rel=0&amp;showinfo=0"
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h3>FEATURES</h3>
        <ul>
          <li>
            DOZENS OF UNIQUE MONSTERS - Each Scroodle-monster has unique stats
            and abilities that require smart strategies to defeat!
          </li>
          <li>
            POWERUPS - Randomly generated powerups add fresh fun every round.
            Can you find a way to use that 'P' in a word to freeze a Scroodle?
          </li>
          <li>
            GAME BOARD MADNESS - Every level has a different 'board' layout,
            sort-of like the bonuses in a Scrabble board. Can you find a way to
            use a 'Q' on the Double Letter square for huge points?!
          </li>
          <li>
            COMPETE WITH FRIENDS - See how you measure up against your Facebook
            friends with level-based LEADERBOARDS!
          </li>
          <li>
            AMAZING CHARACTERS - A Scroodle = Scribble + Doodle. Watch as the
            Scroodles are 'drawn to life' at the start of each level, then
            create words to erase them! Every Scroodle is colorfully illustrated
            and animated with individual voices and character profiles!
          </li>
        </ul>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h3>REVIEWS</h3>
        <div className="row">
          <div className="box">
            <blockquote className="small">
              <p>★★★★★</p>
              <p>
                Really fun and addicting game! I just wish there were more
                levels to play.
              </p>
              <footer>--- Alaina Danielle Gaerlan</footer>
            </blockquote>
          </div>
          <div className="box">
            <blockquote className="small">
              <p>★★★★★</p>
              <p>
                <strong>This is fun!</strong> Great fun. Love the characters.
                Past level 50 without a single purchase. Just got to be smarter
                than the meanies.
              </p>
              <footer>--- Dale Hermann</footer>
            </blockquote>
          </div>
          <div className="box">
            <blockquote className="small">
              <p>★★★★</p>
              <p>
                I've played every word game imaginable, and most are all the
                same. This one isn't the easiest but one of the most enjoyable.
                Something completely different but not impossible to play. Cute
                simple graphics, completely free and no annoying ads that ruin
                gameplay. Thanks for the extra levels.
              </p>
              <footer>--- Tammi Nicholson</footer>
            </blockquote>
          </div>
          <div className="box">
            <blockquote className="small">
              <p>★★★★★</p>
              <p>
                Bottom line is.... Sooooo, yeah! I downloaded this game
                approximately one month ago and defeated all levels of it in 3
                weeks over time! When I say it is addictive, and challenging!
                Goodness! It's the cutest little game that will keep you
                entertained and sweating at the same time. I loved it! So much I
                even downloaded it again because I tried to find another word
                game as exciting, but became easily bored. Scroodles set the bar
                high for every word game!
              </p>
              <footer>--- Harriet Dabbs</footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
    <PrivacyPolicy />
    <section className="section">
      <div className="container">
        <h1 className="text-center">About Mad Sally Games</h1>
        <p className="lead">
          Our mission is to create <em>unique</em> and <em>fun</em> gaming
          experiences. In a sometimes derivative industry, we at Mad Sally take
          pride in our originality, creativity, and dedication to the highest
          standards of <span className="text-primary">fun</span>. Our first
          title, <span className="text-primary">Scroodles</span>, is available
          on the App Store and Google Play Store.
        </p>
        <p className="text-center text-muted">
          Business or press inquiries can be sent to{' '}
          <a href="mailto:madsallygames@gmail.com">madsallygames@gmail.com</a>.
        </p>
      </div>
    </section>
  </Layout>
)

export default IndexPage
